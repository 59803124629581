import {
  createStore,
  compose,
  applyMiddleware
} from 'redux'
import ReduxThunk from 'redux-thunk'
import rootReducer from 'reducers'

const middleware = [ReduxThunk]

const createStoreWithMiddleware = compose(applyMiddleware(
  ...middleware
))(createStore)

export default function configureStore (initialState) {
  return createStoreWithMiddleware(rootReducer, initialState)
}
