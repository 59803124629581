export const routes = [
  {
    path: '/',
    componentName: 'HomePage.jsx',
    privateRouter: false
  },
  {
    path: '/trang-tra-cuu-hien-vat',
    componentName: 'tracuuhienvat/TrangTraCuuCoVat.jsx',
    privateRouter: false
  },
  {
    path: '/thong-tin-co-vat-chi-tiet/:id',
    componentName: 'tracuuhienvat/ThongTinChiTiet.jsx',
    privateRouter: false
  },
  {
    path: '*',
    componentName: 'NotFoundPage.jsx',
    fetchData: 'fetchNotFoundPage',
    status: 404,
    privateRouter: false
  },
]
