import * as React from 'react'
import { useRoutes, useLocation, useParams } from 'react-router-dom'
import { Navigate } from 'react-router'
import loadable from '@loadable/component'
import { routes } from './routes'
import { retry } from 'helpers'
import { withCookies } from 'react-cookie'

const AsyncComponent = ({
  componentName,
  updateContext,
  ...props
}) => {
  const location = useLocation()
  const params = useParams()
  if (props.privateRouter && !props.accessToken) {
    return (
      <Navigate
        to={{
          pathname: '/',
          search: ''
        }}
      />
    )
  }

  const Component = loadable(
    () => retry(() => import(`../components/pages/${componentName}`))
  )

  return (
    <Component
      {...props}
      match={{ params }}
      location={location}
    />
  )
}

const generateRoutes = (
  routes,
  props
) => {
  const { cookies } = props
  const accessToken = cookies.get('accessToken')
  return routes.map(({
    path,
    componentName,
    privateRouter = false
  }) => ({
    element:
  <AsyncComponent
    componentName={componentName}
    accessToken={accessToken}
    privateRouter={privateRouter}
  />,
    path
  }))
}

function RenderRoutes (props) {
  return useRoutes([
    ...generateRoutes(routes, props)
  ])
}

export default withCookies(RenderRoutes)
