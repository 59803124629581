import React from 'react'
import RenderRouter from './routes/renderRoutes'

function App () {
  return (
    <>
      <RenderRouter />
    </>
  )
}

export default App
